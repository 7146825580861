<template>
    <div class="container-fluid add-form-list">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                            <h4 class="card-title">
                                공지
                            </h4>
                        </div>
                    </div>
                    <div class="card-body">
                        <tab-nav :tabs="true" id="myTab-1">
                            <tab-nav-items :active="true" id="register-tab" ariaControls="register" role="tab" :ariaSelected="true" title="등록" />
                            <tab-nav-items :active="false" id="list-tab" ariaControls="list" role="tab" :ariaSelected="false" title="공지 리스트" />
                        </tab-nav>
                        <tab-content id="myTabContent">
                            <tab-content-item :active="true" id="register" aria-labelled-by="register-tab">
                                <div>
                                    <textarea class="textarea form-control" v-model="content" rows="5"></textarea>
                                    <button class="btn btn-primary mr-2 mt-2" type="button" @click="onSubmit">등록</button>
                                </div>
                            </tab-content-item>
                            <tab-content-item :active="false" id="list" aria-labelled-by="list-tab">
                                <CDataTable v-if="showTable" :headers="headers" :items="notifyList" :is-column="false" :is-radio="false" :is-per-page="false">
                                    <template v-slot:date="{item}">
                                        <div>
                                            {{ getTimeDate(item.date) }}
                                        </div>
                                    </template>
                                    <template v-slot:reading="{item}">
                                        <div>
                                            {{ item.reading ? '개시' : '미개시' }}
                                        </div>
                                    </template>
                                    <template v-slot:action="{item}">
                                        <div class="d-flex align-items-center list-action justify-center">
                                            <a class="badge badge-info mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="수정" href="javascript:void(0)" v-b-modal="modalID(item._id)">
                                                <i class="ri-eye-line mr-0 action-img"></i>
                                            </a>
                                            <b-modal :id="'modal' + item._id" size="lg" scrollable title="수정" ok-title="수정" cancel-title="닫기" class="detail-modal" @hidden="onModalHidden" @ok="onChangeContent(item)">
                                                <div>
                                                    <textarea class="textarea form-control" v-model="item.content" rows="5"></textarea>
                                                    <div style="margin-top: 24px">
                                                        <h4 class="mb-2"> 개시여부 </h4>
                                                        <div class="box-area">
                                                            <div class="custom-control custom-radio" style="margin-right: 10px">
                                                                <input
                                                                    type="radio"
                                                                    :id="'true' + item._id"
                                                                    :name="'status' + item._id"
                                                                    v-model="item.reading"
                                                                    :value="true"
                                                                    class="custom-control-input"
                                                                >
                                                                <label class="custom-control-label" :for="'true' + item._id"> 개시</label>
                                                            </div>
                                                            <div class="custom-control custom-radio mb-2">
                                                                <input
                                                                    type="radio"
                                                                    :id="'false' + item._id"
                                                                    :name="'status' + item._id"
                                                                    v-model="item.reading"
                                                                    :value="false"
                                                                    class="custom-control-input"
                                                                >
                                                                <label class="custom-control-label" :for="'false' + item._id"> 미개시</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-modal>
                                            <a class="badge bg-warning mr-2 action-button" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deleteObject(item._id)">
                                                <i class="ri-delete-bin-line mr-0 action-img"></i>
                                            </a>
                                        </div>
                                    </template>
                                </CDataTable>
                            </tab-content-item>
                        </tab-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import CDataTable from '@/components/common/CDataTable';
export default {
    name: 'logisnotify',
    components: {
        CDataTable
    },
    data() {
        return {
            content: '',
            showTable: false,
            notifyList: null,
            headers: [
                {text: '공지내용', value: 'content', align: 'left', width: 100},
                {text: '등록일', value: 'date', align: 'center', width: 100, isSlot: true},
                {text: '개시여부', value: 'reading', align: 'center', width: 100, isSlot: true},
                {text: 'Action', value: 'action', align: 'center', width: 150, isSlot: true}
            ],
            reading: false
        }
    },
    created() {
      this.getNotifyData()
    },
    methods: {
        onSubmit() {
            const date = Date.now()

            if (this.content) {
                const payload = {
                    content: this.content,
                    date: date
                }

                axios.post('/api/logisnotify/insert', payload)
                    .then(response => {
                        if (response.data.요청결과 === 'success') {
                            this.getNotifyData()
                            this.content = ''
                            alert('공지가 등록됐습니다. 공지리스트 > 공지 수정에서 노출여부를 설정 해주세요')
                        }
                    })
                    .catch(e => {
                        alert(e.response.data.message)
                    })
            }
        },
        getNotifyData() {
            this.showTable = false
            axios.post('/api/logisnotify/notify')
                .then((response) => {
                    this.notifyList = response.data
                    this.showTable = true
                    console.log(this.notifyList)
                })
        },
        getTimeDate(timestamp) {
            let date = new Date(timestamp);
            let year = date.getFullYear();
            let month = ("0" + (1 + date.getMonth())).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);
            let hours = ("0" + date.getHours()).slice(-2);
            let minutes = ("0" + date.getMinutes()).slice(-2);
            let seconds = ("0" + date.getSeconds()).slice(-2);

            return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        },
        modalID(index) {
            return 'modal' + index
        },
        deleteObject(id) {
            if (confirm('삭제된 내역은 복구가 불가능합니다.')) {
                axios.post(`/api/logisnotify/delete/${id}`)
                    .then(response => {
                        if (response.data.요청결과 === 'success') {
                            this.getNotifyData()
                        }
                    })
                    .catch(e => {
                        alert(e.response.data.message)
                    })
            }
        },
        updateContent(item) {
            const payload = {
                content: item.content,
                reading: item.reading
            }
            axios.post(`/api/logisnotify/notify/${item._id}`, payload)
                .then((response) => {
                    if (response.data.요청결과 === 'success') {
                        alert('공지가 수정됐습니다.')
                        this.getNotifyData()
                        this.content = ''
                    }
                })
                .catch(e => {
                    alert(e.response.data.message)
                })
        },
        onChangeContent(item) {
            if (confirm('수정 하시겠습니까?')) {
                if (item.reading === true) {
                    const existingActiveItem = this.notifyList.find(notiItem => notiItem.reading === true && notiItem._id !== item._id);

                    if (existingActiveItem) {
                        alert('기존에 개시된 게시물이 존재합니다. 미개시로 변경 후 이용해 주세요.');
                        this.getNotifyData()
                        return;
                    }
                    this.updateContent(item)
                } else {
                    this.updateContent(item)
                }
            }
        },
        onModalHidden() {
            this.content = ''
        },
    }
}
</script>

<style scoped>
.box-area {
    display: flex;
    margin-bottom: 12px;
}
.action-button {
    width: 30px;
    height: 30px;
}
.action-img {
    display: flex;
    justify-content: center;
    margin: 6px 0px;
}
</style>
